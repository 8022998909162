<template>
  <div class="blank-layout row align-content--center">
    <div style="width: 100%">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  components: { },

  data() {
    return {
      selectedTabIndex: 0,
    };
  },

  methods: {
    srcrequire(src) {
      return new URL(`${src}`, import.meta.url)  
    },

    init () { }
  },

  mounted () {
    this.init();
  },

  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss">
.blank-layout {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }
}
</style>
