import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import BlankLayout from '../layouts/blank-layout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

import { useAuthStore } from '@/stores/auth.store'
import { useMutationStore } from '@/stores/mutation.store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },

  {
    name: 'demo',
    path: '/demo',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/demo/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/demo/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/demo/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/demo/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/demo/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/demo/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/demo/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/demo/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/demo/faq/FaqPage.vue'),
      },
    ],
  },

  {
    name: '',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ ], 'not-found-simple') },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ ], 'not-found-simple') },
        component: () => import('../pages/rcms/dashboard/Dashboard.vue'),
      },
    ],
  },

  {
    path: '/erp',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'assets'], 'not-found-simple') },
    children: [
      {
        name: 'article',
        path: 'article',
        component: () => import('../pages/erp/article/article/Article.vue'),
      },
    ],
  },
  

  {
    path: '/assets',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'assets'], 'not-found-simple') },
    children: [
      {
        name: 'company',
        path: 'company',
        component: () => import('../pages/rcms/assets/company/Company.vue'),
      },
      {
        name: 'property',
        path: 'property',
        component: () => import('../pages/rcms/assets/property/Property.vue'),
      },
      {
        name: 'rental-unit',
        path: 'rental-unit',
        component: () => import('../pages/rcms/assets/rental-unit/RentalUnit.vue'),
      },
      {
        name: 'parking-lot',
        path: 'parking-lot',
        component: () => import('../pages/rcms/assets/parking-lot/ParkingLot.vue'),
      },
      {
        name: 'cellar',
        path: 'cellar',
        component: () => import('../pages/rcms/assets/cellar/Cellar.vue'),
      },
      {
        name: 'apartment-type',
        path: 'apartment-type',
        component: () => import('../pages/rcms/assets/apartment-type/ApartmentType.vue'),
      },
    ]
  },

  
  {
    path: '/management',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general', 'management'], 'not-found-simple') },
    children: [
      {
        name: 'tenancy',
        path: 'tenancy',
        component: () => import('@/pages/rcms/rental_management/tenancy/Tenancy.vue'),
      },
      {
        name: 'rental-contract',
        path: 'rental-contract',
        component: () => import('@/pages/rcms/rental_management/rental-contract/RentalContract.vue'),
      },
      {
        name: 'tenant',
        path: 'tenant',
        component: () => import('@/pages/rcms/rental_management/tenant/Tenant.vue'),
      },
    ]
  },
  
  {
    path: '/renting',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'tenant-suggestions'], 'not-found-simple') },
    children: [
      {
        name: 'tenant-suggestion-overview',
        path: 'suggestion/overview',
        component: () => import('@/pages/rcms/renting/suggestion/overview/Overview.vue'),
      },
      {
        name: 'tenant-suggestion-add',
        path: 'suggestion/add',
        component: () => import('@/pages/rcms/renting/suggestion/add/Add.vue'),
      },
    ]
  },

  {
    path: '/appointment',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'appointment'], 'not-found-simple') },
    children: [
      {
        name: 'appointment/service',
        path: 'service',
        component: () => import('@/pages/rcms/appointment/service/AppointmentService.vue'),
      },
      {
        name: 'appointment/user',
        path: 'user',
        component: () => import('@/pages/rcms/appointment/user/AppointmentUser.vue'),
      },
    ]
  },

  {
    path: '/accounting',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
    children: [
      {
        name: 'receivable',
        path: 'receivable',
        component: () => import('@/pages/rcms/accounting/receivable/Receivable.vue'),
      },
      {
        name: 'invoice',
        path: 'invoice',
        component: () => import('@/pages/rcms/accounting/invoice/Invoice.vue'),
      },
      {
        name: 'bank-transfers',
        path: 'bank-transfers',
        component: () => import('@/pages/rcms/accounting/bank-transfers/BankTransfers.vue'),
      },
      {
        name: 'export',
        path: 'export',
        component: () => import('@/pages/rcms/accounting/export/Export.vue'),
      },
    ]
  },
  
  {
    path: '/report',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'report'], 'not-found-simple') },
    children: [
      {
        name: 'report-general',
        path: 'general',
        component: () => import('@/pages/rcms/reports/general/GeneralReport.vue'),
      },
      {
        name: 'report-apartments',
        path: 'apartments',
        component: () => import('@/pages/rcms/reports/apartments/ApartmentReport.vue'),
      },
      {
        name: 'report-rent-arrears',
        path: 'rent-arrears',
        component: () => import('@/pages/rcms/reports/rent-arrears/RentArrearsReport.vue'),
      },
      {
        name: 'report-renting',
        path: 'renting',
        component: () => import('@/pages/rcms/reports/renting/RentingReport.vue'),
      },
      {
        name: 'report-receivables',
        path: 'receivables',
        component: () => import('@/pages/rcms/reports/receivables/ReceivablesReport.vue'),
      },
      {
        name: 'report-rental-contracts',
        path: 'rental-contracts',
        component: () => import('@/pages/rcms/reports/rental-contracts/RentalContractReport.vue'),
      },
      {
        name: 'report-tenants',
        path: 'tenants',
        component: () => import('@/pages/rcms/reports/tenants/TenantsReport.vue'),
      },
      {
        name: 'report-analytics',
        path: 'analytics',
        component: () => import('@/pages/rcms/reports/analytics/AnalyticsReport.vue'),
      },
      {
        name: 'datev',
        path: 'datev',
        component: () => import('@/pages/rcms/reports/datev/Datev.vue'),
      },
    ]
  },
  
  {
    path: '/import',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'property'], 'not-found-simple') },
    children: [
      {
        name: 'import-bank-transfer',
        path: 'bank-transfer',
        component: () => import('@/pages/rcms/import/bank-transfer/ImportBankTransfer.vue'),
      },
    ]
  },

  {
    path: '/handover',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'handover'], 'not-found-simple') },
    children: [
      {
        name: 'handover-protocol',
        path: 'protocol',
        component: () => import('@/pages/rcms/handover/protocol/Protocol.vue'),
      },
    ]
  },

  {
    path: '/scouting',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'scouting'], 'not-found-simple') },
    children: [
      {
        name: 'property-scouting-service-provider',
        path: 'property-service-provider',
        component: () => import('@/pages/rcms/scouting/property/PropertyScoutingServiceProvider.vue'),
      },
      {
        name: 'property-scouting-condition-assessment',
        path: 'property-condition-assessment',
        component: () => import('@/pages/rcms/scouting/property/PropertyScoutingConditionAssessment.vue'),
      },
    ]
  },

  {
    name: 'viewing',
    path: '/viewing',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'viewing'], 'not-found-simple') },
    children: [
      {
        name: 'viewing-apartment-overview',
        path: 'apartment/overview',
        component: () => import('@/pages/rcms/viewing/apartments/overview/Overview.vue'),
      },
      {
        name: 'viewing-apartment-add',
        path: 'apartment/add',
        component: () => import('@/pages/rcms/viewing/apartments/createedit/CreateEdit.vue'),
      },
      {
        name: 'viewing-handover-protocol',
        path: 'handover/protocol',
        component: () => import('@/pages/rcms/viewing/handover/protocol/Protocol.vue'),
      },
    ]
  },
  
  {
    path: '/settings',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'settings'], 'not-found-simple') },
    children: [
      {
        name: 'settings',
        path: '',
        component: () => import('@/pages/rcms/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/database',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'database'], 'not-found-simple') },
    children: [
      {
        name: 'cpi',
        path: 'cpi',
        component: () => import('@/pages/rcms/database/cpi/CPI.vue'),
      },
    ]
  },

  {
    name: 'cms',
    path: '/cms',
    component: AppLayout,
    children: [
      {
        name: 'cms/media-library',
        path: 'media-library',
        component: () => import('@/pages/rcms/cms/media-library/MediaLibrary.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], 'not-found-simple') },
      },
    ]
  },

  {
    name: 'analytics',
    path: '/analytics',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'analytics'], 'not-found-simple') },
    children: [
      {
        name: 'analytics/visitor',
        path: 'visitor',
        component: () => import('@/pages/rcms/analytics/visitor/VisitorAnalytics.vue'),
      },
    ]
  },

  {
    name: 'letter-template',
    path: '/letter-template',
    component: BlankLayout,
    children: [
      {
        name: 'letter-template/short-message',
        path: 'short-message',
        component: () => import('@/pages/rcms/letter-template/short-message/ShortMessage.vue'),
      },
    ]
  },

  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin'], 'not-found-simple') },
    children: [
      {
        name: 'admin/user',
        path: 'user',
        component: () => import('@/pages/rcms/admin/user/User.vue'),
      },
      {
        name: 'admin/settings',
        path: 'settings',
        component: () => import('@/pages/rcms/admin/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },

  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

const isAllowed = (to:any, from:any, next:any, permissions:Array<string>, reditectTo:string) => {
  const authStore = useAuthStore()

  if (to.name == 'login') {
    if(authStore?.token) {
      next({ name: '' })
    } else {
      next();
    }
  } else {
    let check = checkPermissions(permissions)

    if(check.allowed && authStore?.token) {
      next();
    } else {
      if(authStore?.token) {
        next({ name: reditectTo })
      } else {
        next({ name: 'login' })
      }
    }
  }
}

const checkPermissions = (permissions:Array<string>) => {
  const authStore = useAuthStore()

  // return { allowed: true, redirectTo: null }

  if(authStore?.user?.role?.name == 'admin') {
    return { allowed: true, redirectTo: null };
  }

  if(permissions.length == 0) {
    return { allowed: true, redirectTo: null };
  } else if(permissions.length > 0) {
    if(authStore?.token && authStore?.user?.role) {
      for(let permission of permissions) {
        if(authStore?.user?.role?.permissions.map((data: any) => { return data.name }).indexOf(permission) > -1) {
          return { allowed: true, redirectTo: null };
        }
      }

      return { allowed: false, redirectTo: '' };
    } else {
      return { allowed: false, redirectTo: 'login' };
    }
  } else {
    return { allowed: false, redirectTo: '' };
  }
}

router.beforeEach(async (to:any, from:any, next:any) => {
  const authStore = useAuthStore()
  const mutationStore = useMutationStore();

  let redirect = mutationStore.auth_guard({name: to?.name, query: to?.query});

  if(!redirect) {
    next(false);
  } else {
    if (to.name == 'login') {
      if(authStore?.token) {
        next();
      } else {
        next();
      }
    } else {
      next();
    }
  }
})

export default router
